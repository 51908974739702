<template>
    <v-dialog
      v-model="show"
      width="500"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 white lighten-2">
          Log ind for at få adgang
        </v-card-title>
        <v-card-text class="mt-2 mb-2">
        Du har desværre ikke adgang til kurserne. For
         at få adgang til materialet, skal du først være logget ind i SprogtrappeHuset.
          Hvis du er logget
          ind i SprogTrappeHuset så prøv at <a href="." class="link">refresh</a> siden.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="action-button"
            href="https://sprogtrappen.dk"
          >
            Gå til SprogTrappeHuset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>

export default {
    name: 'AuthorizationModal',

    components: {
    },

    computed: {
        show: function checkAuth() {
            return !this.$store.state.auth.auth;
        },
    },

    beforeMount() {},

    mounted() {},

    methods: {},
};
</script>

<style scoped>
.action-button {
    font-family: "Circe-rounded", sans-serif;
    font-weight: 600;
    text-transform: none;
    background-color: var(--v-button-base) !important;
    padding: 20px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    color: var(--v-button_text-base);
}

.link {
    background-color: var(--v-button-base) !important;
    color: var(--v-button_text-base) !important;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left:4px;
    padding-right: 4px;
    border-radius:4px;
    text-decoration: none;
}
</style>
